.card-coretask {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: #449eda;
}
.card-workprocesses {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: rgb(177, 201, 24);
}
.card-competences {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: #37cc60;
}
.card-skills {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: rgb(201, 102, 201);
}
.card-top-logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  top: 4%;
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
}
.card-logo {
  width: 30px;
  height: 30px;
}

.itemTotal {
  list-style: none;
  font-size: 14px;
  font-weight: 600;
}
