.dropdown-item.active,
.dropdown-item:active {
  background-color: #4f16b6 !important;
}
@media only screen and (max-width: 600px) {
  .navbar-brand {
    font-size: 1rem !important;
    margin-right: 0.2rem !important;
  }
}

.dropdown-menu.show {
  margin-bottom: 0.8rem !important;
}
