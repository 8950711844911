.scroll-to-top {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 50px;
  bottom: 20px;
  z-index: 10;
  right: 1%;
  color: white;
  border: 1px solid #4f16b6;
  background-color: #4f16b6;
  border-radius: 100%;
  padding: 0.2rem;
  opacity: 0.9;
}
