.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.img-circle img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border: 5px solid #fff;
}
.nav-pills a.nav-link {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  color: #333;
}

.nav-pills a.nav-link.active {
  background-color: #4f16b6 !important;
}
svg {
  vertical-align: baseline !important;
}
.form-change {
  border: none !important;
  max-width: 100% !important;
  box-shadow: none !important;
  padding: 10px 0 !important;
}

.title-detail {
  color: #4f16b6;
}

.btn-default {
  border-color: rgba(24, 28, 33, 0.1) !important;
  background: rgba(0, 0, 0, 0);
  color: #4e5155 !important;
}

label.btn {
  margin-bottom: 0;
}
.btn-upload {
  border-color: #4f16b6 !important;
  background: transparent;
  color: #4f16b6 !important;
}
.btn-upload:hover {
  border-color: #4f16b6 !important;
  background: #4f16b6;
  color: white !important;
}
.btn-save {
  border-color: #4f16b6 !important;
  background: #4f16b6 !important;
  color: white !important;
}
.btn-save:hover {
  border-color: #4f16b6 !important;
  background: #5b1bd1 !important;
  color: white !important;
}
.btn-delete {
  border-color: red !important;
  background: transparent !important;
  color: red !important;
}
.btn-delete:hover {
  background: red !important;
  color: white !important;
}
.btn {
  cursor: pointer;
}

.text-light {
  color: #babbbc !important;
}

.row-bordered {
  overflow: hidden;
}

.settings-fileinput {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}
.change-hover:hover {
  color: #4f16b6;
}

.invite-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: rgb(92, 92, 92);
  border: none;
  background-color: rgb(241, 241, 241);
  padding: 0.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
