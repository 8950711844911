.loading_spinner-large {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: white;
}

.loading_spinner-small {
  width: 100;
  background-color: white;
}
