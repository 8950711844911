.btn {
  margin-bottom: 5px;
}

.grid {
  position: relative;
  width: 100%;
  background: #fff;
  color: #666666;
  border-radius: 2px;
  margin-bottom: 25px;
}

.grid .grid-body {
  padding: 15px 20px 15px 20px;
  font-size: 0.9em;
  line-height: 1.9em;
}
.grid .grid-title {
  padding: 1rem 0;
}
.box-chosen-option {
  color: white;
  background-color: #4f16b6;
  padding: 0.2rem;
  border-radius: 5px;
  /* display: flex; */
  text-align: center;
  margin: 0.5rem auto 1rem auto;
}
.search-bar {
  margin: 0.5rem auto 1rem auto;
}
.search-button {
  background-color: #4f16b6;
  border: 1px solid #4f16b6;
  color: white;
  border-radius: 20px;
  text-align: center;
  font-size: larger;
  padding: 0.4rem;
  margin: 0.5rem auto 0 auto;
  width: 100%;
}
.reset {
  background-color: white;
  color: #4f16b6;
}
.search-button:hover {
  background-color: #4f16b6;
  color: white;
  border-radius: 20px;
  padding: 0.4rem;
  cursor: pointer;
  transition: 0.3s;
}
.search-type {
  padding: 0.4rem;
  cursor: pointer;
}
.search-type:hover {
  padding: 0.4rem;
  cursor: pointer;
  color: white;
  background-color: #4f16b6;
  transition: 0.5s;
  border-radius: 5px;
}
.search-type-active {
  border-radius: 5px;
  padding: 0.4rem;
  cursor: pointer;
  color: white;
  background-color: #4f16b6;
}
.option {
  background-color: #4f16b6;
  color: white;
  padding: 0.3rem 0.5rem;
  margin: 0.2rem;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.button-viewCandidate {
  background-color: white;
  color: #4f16b6;
  border: 1px solid #4f16b6;
  border-radius: 4px;
  padding: 0.1rem 0.4rem;
}
.button-viewCandidate:hover {
  background-color: #4f16b6;
  color: white;
  transition: 0.5s;
}

.rbt .rbt-token {
  background-color: #4f16b6;
  color: white;
}
