.row-wrapper {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
  display: flex;
  margin-top: 0.5rem;
}

.row-title {
  padding-left: 0;
  flex: 10;
}

.button-second {
  background-color: #4f16b6;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
