.table thead th {
  border-top: none;
}

.bookmark-item-status-button {
  padding: 0.4rem;
  border-radius: 0.2rem;
}
.bookmark-item-status-button.available {
  background-color: rgb(139, 255, 139);
}
.bookmark-item-status-button.unavailable {
  background-color: rgb(255, 91, 91);
}
