.footer {
  position: absolute;
  text-align: center;
  margin-top: 5rem;
  padding-top: 1rem;
  font-size: 14px;
  z-index: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.footer-list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem 1rem 0 1rem;
}
@media only screen and (max-width: 550px) {
  ul.footer-list {
    display: block;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -moz-column-fill: auto;
    column-fill: auto;
  }
}

.footer-list-item {
  padding: 0 0.5rem;
}
.footer-list-item a {
  text-decoration: none !important;
  color: inherit;
}
.footer-list-item a:hover {
  color: #5402ec;
}
.copyright {
  color: rgb(100, 100, 100);
  font-size: 10px;
}
