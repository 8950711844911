.auth.container {
  padding-top: 5rem !important;
  padding-bottom: 2rem !important;
}
.auth-form {
  max-width: auto;
  margin: 0 auto;
  padding: 0.8rem;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid rgb(240, 240, 240);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 2560px) {
  .auth-form {
    width: 600px;
  }
}
@media only screen and (max-width: 768px) {
  .auth-form {
    width: 98%;
  }
}

.form_title {
  text-align: center;
}
@media only screen and (max-width: 425px) {
  .form_title h2 {
    font-size: 1.5rem;
  }
}
.password-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eye-icon {
  cursor: pointer;
  position: absolute;
  right: 3%;
}
.eye-icon:hover {
  cursor: pointer;
  color: #5402ec;
}

.forgot-password a {
  color: inherit !important;
  text-decoration: none !important;
}

.forgot-password a:hover {
  color: #5402ec !important;
  text-decoration: none !important;
}
.auth-text a {
  color: #5402ec !important;
  text-decoration: none !important;
}
.forgot-password {
  font-size: smaller;
}

.button_primary {
  background-color: #4f16b6;
  margin-top: 20px;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
.button_primary:hover {
  background-color: #5402ec;
}

.button_secondary {
  background-color: #ff6d62;
  margin-top: 20px;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 20px;
}

.button_secondary:hover {
  background-color: #c55e5e;
}

.button_outline {
  border: 1px solid #4f16b6;
  color: #4f16b6;
  background-color: white;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.button_outline:hover {
  border: 1px solid #4f16b6;
  background-color: #4f16b6;
  color: white !important;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
}
.required {
  color: red;
}
.error__message {
  color: #bf1650;
  font-size: small;
  padding-top: 0.5rem;
  margin-bottom: 0;
}

.error__message::before {
  display: inline;
  content: "⚠ ";
}
