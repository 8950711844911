.App {
  position: relative;
  min-height: 100vh;
}
.page {
  padding-bottom: 10rem;
  padding-top: 4rem;
}
.container {
  color: #3e3e3e !important;
}
