.view-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.view-button {
  border: 1px solid lightgray;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.5rem;
}
.view-button:hover {
  background-color: rgb(223, 223, 223);
}

.view-button-active {
  border: 1px solid lightgray;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: gray;
  color: white;
  margin-left: 0.5rem;
}
