.header {
  background-image: url("../../assets/scorecards-coach360-skillswipes-bg.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-color: #41b5e8;
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

.header-wrapper {
  height: 18rem;
  padding: 1rem 0;
  text-align: center;
  background-color: white;
}

@media only screen and (max-width: 575px) {
  .header {
    height: 20rem;
  }
  .header-wrapper {
    height: 20rem;
    padding-top: 3rem;
  }
  .header-profile-name {
    font-size: 1.25rem;
  }
}
