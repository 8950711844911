html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/src/assets/scorecards-coach360-skillswipes-bg.png"),
    url("/src/assets/background-studycard-doodles-01.png");

  background-position: right bottom, left top;
  background-repeat: no-repeat, repeat;
  background-attachment: local;
  overflow-x: hidden;
  width: 100%;
}
