.skill-groups {
  margin-top: 0.5rem;
}
.skill-group {
  margin-bottom: 1rem;
}
.skill-group-skills-extend {
  column-count: 2;
}
@media only screen and (max-width: 550px) {
  .skill-group-skills-extend {
    column-count: 1;
  }
}
.skill-group-skill-wrapper {
  padding: 0.5rem 0;
}
.skill-groups .skill-group-name {
  font-weight: bolder;
}
