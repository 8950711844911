.stat-button-link {
  border: none;
  background: none;
  color: #3e3e3e;
}
.stat-button-link:hover {
  border: none;
  background: none;
  color: #4f16b6;
}
.stat-button-link-active {
  border: none;
  background: none;
  color: #4f16b6;
}

@media only screen and (max-width: 320px) {
  .stat-wrapper {
    font-size: 12px;
  }
}
